import React from "react";
import Fraction from './../lib/Fraction';
import Latex from 'react-latex';

export enum Operation { UNDEFINED, SUM, DIFFERENCE, PRODUCT, QUOTIENT }

export class FractionEquation {
    operand1 : Fraction = Fraction.null();
    operand2 : Fraction = Fraction.null();
    operation : Operation = Operation.UNDEFINED;

    constructor(operation : Operation, operand1 : Fraction, operand2 : Fraction) {
        this.operation = operation;
        this.operand1.numerator = operand1.numerator;
        this.operand1.denominator = operand1.denominator;
        this.operand2.numerator = operand2.numerator;
        this.operand2.denominator = operand2.denominator;
    }

    private operationToString() : string {
        let s : string = "";
        switch (this.operation)
        {
            case Operation.SUM: s = "+"; break;
            case Operation.DIFFERENCE: s = "-"; break;
            case Operation.PRODUCT: s = "\\cdot"; break;
            case Operation.QUOTIENT: s = "\\div"; break;
        }
        return s;
    }

    get solution() : Fraction {
        let s : Fraction = Fraction.null();
        switch (this.operation)
        {
            case Operation.SUM: s = Fraction.sum(this.operand1, this.operand2); break;
            case Operation.DIFFERENCE: s = Fraction.difference(this.operand1, this.operand2); break;
            case Operation.PRODUCT: s = Fraction.product(this.operand1, this.operand2); break;
            case Operation.QUOTIENT: s = Fraction.quotient(this.operand1, this.operand2); break;        
        }
        return s;
    }

    toString(solved : boolean) : string {
        let s : string = "$ ";
        s += this.operand1.toMixedNumberString();
        s += " " + this.operationToString() + " ";
        s += this.operand2.toMixedNumberString();
        if (solved)
        {
            s += " = ";
            s += this.solution.toMixedNumberString();
        }
        s += " $";
        return s;
    }
}
