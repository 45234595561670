import React from 'react';
import logo from './logo.svg';
import './App.css';
import Latex from 'react-latex';
import Fraction from './lib/Fraction';
import FractionSet from './components/FractionSet';
import { FractionEquation, Operation } from './components/FractionEquation';

function App(): JSX.Element {
  let myFractions : Fraction[] =
  [
  ];

  let myFEquations : FractionEquation[] = 
  [
    new FractionEquation(Operation.DIFFERENCE, new Fraction(3,8), new Fraction(1,17)),
    new FractionEquation(Operation.PRODUCT, new Fraction(1,2), new Fraction(2,5)),
    new FractionEquation(Operation.QUOTIENT, new Fraction(3,8), new Fraction(1,2)),
  ]

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <FractionSet title="Awesome Fraction Set" fractions={myFractions} />
        <p><Latex>{myFEquations[0].toString(true)}</Latex></p>
        <p><Latex>{myFEquations[1].toString(true)}</Latex></p>
        <p><Latex>{myFEquations[2].toString(true)}</Latex></p>
      </header>
    </div>
  );
}

export default App;
