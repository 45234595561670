class Fraction {
    numerator : number = 0;
    denominator : number = 1;

    constructor(numerator : number, denominator : number) {
        this.numerator = numerator;
        this.denominator = denominator;
    }

    get is_null() { return this.numerator === 0; }

    randomize() {
        this.denominator = Math.trunc(Math.random() * 10) + 1;
        this.numerator = Math.trunc(Math.random() * 20);
    }

    simplify() {
        if (this.denominator < 0) {
            this.numerator = -this.numerator;
            this.denominator = -this.denominator;
        }

        let n : number = this.gcd(this.numerator, this.denominator);
        this.numerator = this.numerator / n;
        this.denominator = this.denominator / n;
    }

    gcd(a : number, b : number) {
        a = Math.abs(a);
        b = Math.abs(b);

        do {
            let remainder : number = a % b;
            if (remainder === 0) return b;
            a = b;
            b = remainder;
        } while (true);
    }

    toString() {
        let sign : string = this.numerator < 0 ? "-" : "";
        let n : number = Math.abs(this.numerator);
        return `${sign}\\frac{${n}}{${this.denominator}}`;
    }

    toMixedNumberString() {
        this.simplify();
        let sign : string = this.numerator < 0 ? "-" : "";
        let n : number = Math.abs(this.numerator) % this.denominator;
        let integer : number | string = Math.trunc(this.numerator / this.denominator);
        if (integer === 0) integer = "";
        if (n != 0) {
            return `${sign}${integer}\\frac{${n}}{${this.denominator}}`;
        }
        else {
            return `${sign}${integer}`;
        }
    }

    static null () : Fraction {
        return new Fraction(0, 1);
    }

    static sum (a : Fraction, b : Fraction) : Fraction {
        let s : Fraction = new Fraction(
            a.numerator * b.denominator + a.denominator * b.numerator,
            a.denominator * b.denominator);
        s.simplify();
        return s;
    }

    static negative (a : Fraction) : Fraction {
        return new Fraction(a.numerator, -1 * a.denominator);
    }

    static difference (a : Fraction, b : Fraction) : Fraction {
        return Fraction.sum(a, Fraction.negative(b));
    }

    static product (a : Fraction, b : Fraction) : Fraction {
        let s : Fraction = new Fraction(
            a.numerator * b.numerator,
            a.denominator * b.denominator);
        s.simplify();
        return s;
    }

    static invert (a : Fraction) : Fraction {
        return new Fraction(a.denominator, a.numerator);
    }

    static quotient (a : Fraction, b : Fraction) : Fraction {
        return Fraction.product(a, Fraction.invert(b));
    }
}

export default Fraction;
