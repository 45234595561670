import React from "react";
import Fraction from './../lib/Fraction';
import Latex from 'react-latex';

type Props = {
    title: string;
    fractions: Fraction[];
}

function printFraction(f : Fraction)
{
    return `$ ${f.toMixedNumberString()} $`;
}

const FractionSet: React.FC<Props> = ({
    title, fractions
}) => (
    <>
    <h3>{title}</h3>
    <div>
        {fractions.map(fraction => (
            <p><Latex>{printFraction(fraction)}</Latex></p>
        ))}
    </div>
    </>
);

export default FractionSet;

